import React from "react";
import { IconProps } from "../icon";

export const DistributeSpacingHorizontalIcon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 21V3M3 21V3m6 5v8c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C10.602 19 11.068 19 12 19c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C15 17.398 15 16.932 15 16V8c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C13.398 5 12.932 5 12 5c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C9 6.602 9 7.068 9 8z"
    />
  </svg>
);
