import React from "react";
import { IconProps } from "../icon";

export const Maximize_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 10l7-7m0 0h-6m6 0v6m-11 5l-7 7m0 0h6m-6 0v-6"
    />
  </svg>
);
