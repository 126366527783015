import React from "react";
import { IconProps } from "../icon";

export const AlignHorizontalCentre_01Icon = ({
  size = "24px",
  color = "#000",
  ...props
}: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 3v18m10-9h-6.5m0 0l4 4m-4-4l4-4M2 12h6.5m0 0l-4 4m4-4l-4-4"
    />
  </svg>
);
