import React from "react";
import { IconProps } from "../icon";

export const AlignVerticalCenter_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 12h18M12 2v6.5m0 0l4-4m-4 4l-4-4M12 22v-6.5m0 0l4 4m-4-4l-4 4"
    />
  </svg>
);
