import React from "react";
import { IconProps } from "../icon";

export const AlignRight_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 21V3M3 12h14m0 0l-7-7m7 7l-7 7"
    />
  </svg>
);
