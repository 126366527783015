import React from "react";
import { IconProps } from "../icon";

export const SpacingHeight_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M21 3H3m18 18H3m9-3.5v-11m3 0H9m6 11H9"
    />
  </svg>
);
