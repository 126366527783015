import React from "react";
import { IconProps } from "../icon";

export const AlignRight_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M18 10c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C21 8.398 21 7.932 21 7c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C19.398 4 18.932 4 18 4h-8c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C7 5.602 7 6.068 7 7c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C8.602 10 9.068 10 10 10h8zM18 20c.932 0 1.398 0 1.765-.152a2 2 0 001.083-1.083C21 18.398 21 17.932 21 17c0-.932 0-1.398-.152-1.765a2 2 0 00-1.083-1.083C19.398 14 18.932 14 18 14H6c-.932 0-1.398 0-1.765.152a2 2 0 00-1.083 1.083C3 15.602 3 16.068 3 17c0 .932 0 1.398.152 1.765a2 2 0 001.083 1.083C4.602 20 5.068 20 6 20h12z"
    />
  </svg>
);
